@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer-links li {
  list-style: none;
}

.footer-links li a {
  color: #fff;
  font-size: 13px;
  text-align: right;
}

.footer-links li a:hover {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mobile-screen {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  transition: all 0.45s linear;
  visibility: hidden;
}

.mobile-screen.open {
  top: 0;
  z-index: 0;
  visibility: visible;
  transition: all 0.45s linear;
}

.transit {
  transform: all 0.45s linear;
}

.homeBanner {
  background: url("assets/images/header-new4.jpg");
  background-size: cover;
  background-position: -300px -0px;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}

.aboutBanner {
  background: url("assets/images/about-header2.jpg");
  background-size: cover;
  background-position: 0px -0px;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}

.abt2 {
  background: url("assets/images/about-info.jpg");
  background-size: cover;
  background-position: -410px 0px;
  background-blend-mode: overlay;
}

.abt-count {
  background: url("assets/images/about-stats2.jpg");
  background-size: cover;
  background-position: 0px 0px;
  background-blend-mode: overlay;
}

.how_it_works {
  background: url("assets/images/howitworks-header.jpg");
  background-size: cover;
  background-position: -300px -0px;
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
}

.howb {
  background: url("assets/images/howb.jpg");
}

@media screen and (min-width: 0px) and (max-width: 575px) {
  .homeBanner {
    background: url("assets/images/header-new4.jpg");
    background-size: cover;
    background-position: -1120px 0px !important;
    background-color: rgba(0, 0, 0, 0);
    background-blend-mode: overlay;
  }

  .abt2 {
    background: url("assets/images/about-info.jpg");
    background-size: cover;
    background-position: 0px 0px;

    background-blend-mode: overlay;
  }
  .register-bg {
    background: url("assets/images/reg-bg.jpg");
    background-size: cover;
    background-position: 0px 0px !important;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
  }
}

.Ytbg {
  background: url("assets/images/ytbg.png");
  background-size: contain;
  background-position: 0px 0px;
}

.img-scale:hover {
  transform: scale(0.95);
  transition: 0.1s ease-in all;
  border: 2px solid #d71d6d;
}

.testi1 {
  background: url("assets/images/testimonials-bg.jpg");
  background-size: cover;
  background-position: 0px 0px;
}

.whatare {
  background: url("assets/images/howitworks-testimonials2.jpg");
  background-size: cover;
  background-position: 0px 0px;
}

.register-bg {
  background: url("assets/images/reg-bg.jpg");
  background-size: cover;
  background-position: 0px -100px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}

.fp-bg {
  background: url("assets/images/fpbg.jpg");
  background-size: cover;
  background-position: 0px -100px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}

.reg-btn:hover {
  background-color: #ebd165 !important;
  color: #fff !important;
}

select {
  border: none;
  outline: #7c9eef !important;
  padding: 1.2% 3%;
  border-radius: 5px;
}

input {
  border: none;
  outline: none;
}

select:active {
  border: 1px solid #7c9eef !important;
}

.hideRight {
  right: -20000000px;
  transition: all 0.5s ease !important;
}

.showRight {
  right: 0px !important;
  visibility: block;
  transition: all 1s linear !important;
}

.menubar {
  right: -20000000px;
  transition: all 1s linear !important;
  display: none;
}

.MuiAccordionSummary-root {
  background-color: #f8f8f8 !important;
  box-shadow: none !important;
}

.Mui-expanded {
  background-color: #f8f8f8 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  padding: 2px 2px !important;
}

.PrivateTabIndicator-colorPrimary-4 {
  background-color: #d71d6d !important;
}
/* 
.css-19kzrtu {
  padding: 24px !important;
  width: 100% !important;
} */

.verifyScreen {
  background: url("assets/images/lightbg.jpg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
}

.chatbg {
  background: url("./assets/images/chatbg2.png");
}
